import axios from "axios";
import { API_SERVER, defaultAxiosConfig, WINDOW_OPEN_PARMS } from "../../constants";
import { convertBlobToBase64 } from "../../helpers";
import { toast } from "react-toastify";

export const fetchOrder = async (id, fromArtwork) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${API_SERVER}portal/orders/guid/${id}${!!fromArtwork ? "?artwork=true" : ""}`,
      headers: defaultAxiosConfig().headers,
    });
    return response.data || { order: {} } ;

  } catch (error) {
    toast.error(error?.response?.data?.message || `Unable to retrieve order`);
    return {};
  };
};

export const acceptArtwork = async(guid) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${API_SERVER}portal/artwork/accept/guid/${guid}`,
      headers: defaultAxiosConfig().headers,
    });
    return response;
  } catch (error) {
    return {data: error.response.data};
  };
};

export const contactRep = async({notes, orderId, customerName, emailStatus, currentStatusId}, guid) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${API_SERVER}portal/contact-rep/guid/${guid}`,
      data: {
        body: notes,
        subject: `!!${customerName} has questions on proofs for Order #${orderId}`,
        emailStatus,
        currentStatusId,
      },
      headers: defaultAxiosConfig().headers,
    });
    return response;
  } catch (error) {
    return {data: error.response?.data};
  };
};

// the artwork property instructs the api to mark the order as artwork approved and send an email to the printer
export const submitPayment = async(guid, amount, selectedItems, paymentInfo, artwork, isAch) => {
  try {
    if (amount <= 0) {
      toast.error("Validation Error");
      return {data: {status: -1, message: ""}};
    };

    const response = await axios({
      method: "POST",
      url: `${API_SERVER}portal/orders/guid/${guid}/submit`,
      headers: defaultAxiosConfig().headers,
      data: {
        amount,
        selectedItems,
        paymentInfo,
        artwork,
        isAch,
      },
    });
    return response;
  } catch (error) {
    return {data: error.response.data};
  };
};

export const fetchGatewayReceiptPdf = async (uuid, transactionIds) => {
  try {
    if (!transactionIds) return null;

    const response = await axios({
      method: "POST",
      responseType: "blob", //Force to receive data in a Blob Format
      url: `${API_SERVER}portal/generate-gateway-receipt`,
      headers: defaultAxiosConfig().headers,
      data: {
        uuid,
        transactionIds,
      },
    });
    const file = new Blob(
      [response.data], 
      {type: 'application/pdf'});
    
    return await convertBlobToBase64(file);
  } catch (err) {
    return null;
  };
};

export const fetchAchAuthPdf = async (uuid) => {
  try {
    const response = await axios({
      method: "POST",
      responseType: "blob", //Force to receive data in a Blob Format
      url: `${API_SERVER}portal/generate-ach-authorization`,
      headers: defaultAxiosConfig().headers,
      data: {
        uuid,
      },
    });
    const file = new Blob(
      [response.data], 
      {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "Authorization", WINDOW_OPEN_PARMS);

    URL.revokeObjectURL(fileURL);
    
    return await convertBlobToBase64(file);
  } catch (err) {
    return null;
  };
};

export const sendEmail = async (options) => {
  const styling = `<style>h3,p { margin: 0;}</style>`
  const {from, cc, to, subject, body, pdfName, pdfContent, type} = options;
  try {
    const results = await axios({
      method: "POST",
      url: `${API_SERVER}email`,
      data: {
        from,
        to,
        cc,
        subject,
        body: styling + body,
        attachment: {
          fileName: pdfName,
          content: pdfContent,
        },
        type: type,
        order: {...options.order},
      },
      headers: defaultAxiosConfig().headers,
    });

    if (results.data.response.indexOf("250 OK") === -1) {
      toast.error(`Email error ${results.data.response}`);
      return false;
    }
    
    toast.success(`Email sent`);
    return true;
  
  } catch(error) {
    toast.error(`Email error ${error?.response?.data?.message}`);
    return false;
  };
};